/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { MetaData } from '../components/common/meta';
import TagCover from '../images/blog/tag-cover.svg';
import BlogPostCard from '../components/blogCards/postCard';
import BlogLayout from '../components/layout/blogLayout';
import RelatedPosts from '../components/relatedPostsBlog';
import Breadcrumb from '../components/breadcrumb';
import { saveURLParamToSession } from '../utils';

/**
 * Tag page (/tag/:slug)
 *
 * Loads all posts for the requested
 *
 */
const Tag = (props) => {
  const { data, location } = props;
  const tag = data.ghostTag;
  const posts = data.allGhostPost.edges;

  useEffect(() => {
    saveURLParamToSession();
  }, []);

  return (
    <>
      <MetaData data={data} location={location} type="series" />
      <BlogLayout location={location}>
        <>
          <Breadcrumb type="tag" title={tag.name} />
          <div className="tag-container">
            {/* style={{ background: `${tag.accent_color} url(${tag.feature_image})` }} */}
            <header className="tag-header" style={{ backgroundColor: tag.accent_color || 'rgba(255, 241, 213, 0.51)', backgroundImage: `url(${tag.feature_image || TagCover})` }}>
              <div className="tag-meta container">
                <h1 className="tag-title">{tag.name}</h1>
                {tag.description ? <p className="tag-description sub-title-text">{tag.description}</p> : null}
              </div>
              <img className="tag-image d-none" src={tag.feature_image || TagCover} alt={tag.name} />
            </header>
            <section className="post-feed container">
              <div className="row-new">
                {posts.map(({ node }) => (
                  <div key={node.id} className="col-6">
                    <BlogPostCard {...node} />
                  </div>
                ))}
              </div>
            </section>
          </div>
          <div className="container popular-posts">
            <h4 className="title">Popular posts you may like</h4>
            <RelatedPosts />
          </div>
        </>
      </BlogLayout>
    </>
  );
};

Tag.propTypes = {
  data: PropTypes.shape({
    ghostTag: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string
    }),
    allGhostPost: PropTypes.object.isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default Tag;

export const pageQuery = graphql`
  query GhostTagQuery($slug: String!) {
    ghostTag(slug: { eq: $slug }) {
      id
      name
      meta_title
      meta_description
      og_description
      og_title
      og_image
      postCount
      twitter_title
      twitter_image
      twitter_description
      slug
      feature_image
      description
      count {
        posts
      }
      accent_color
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          id
          slug
          title
          primary_tag {
            name
            slug
          }
          primary_author {
            name
            profile_image
            slug
          }
          excerpt
          feature_image
          published_at(formatString: "MMM DD, YYYY")
          reading_time
        }
      }
    }
  }
`;
