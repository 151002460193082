/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useNavigate } from '@reach/router';
import './styles.scss';

const RelatedPosts = (props) => {
  const navigate = useNavigate();
  const { relatedPosts } = props;

  // console.log(relatedPosts);

  const data = useStaticQuery(graphql`
    {
      allGhostPost(sort: { fields: published_at, order: DESC }, limit: 5) {
        edges {
          node {
            id
            title
            slug
            feature_image
            primary_author {
              profile_image
              name
              cover_image
              slug
            }
            primary_tag {
              name
              slug
            }
            published_at(formatString: "MMM DD, YYYY")
            title
          }
        }
      }
    }
  `);

  const { edges } = relatedPosts || data.allGhostPost;

  // eslint-disable-next-line no-console
  // console.log(edges);

  const handleTagClick = (eve, tagSlug) => {
    eve.preventDefault();
    navigate(`/blog/category/${tagSlug}/`);
  };

  return (
    <>
      <div className="related-posts-container">
        {edges && edges.length ? (
          edges.map(({ node }) => (
            <Link
              className="related-post"
              key={node.id}
              to={`/blog/${node.slug}`}
            >
              <div className="related-post-card">
                <div className="related-post-image">
                  <img src={node.feature_image} alt={node.title} />
                </div>
                <div className="related-post-card-body">
                  <h6 className="related-post-tag cursor-pointer" onClick={(event) => handleTagClick(event, node.primary_tag.slug)}>{node.primary_tag?.name}</h6>
                  <h2 className="related-post-title" title={node.title}>{node.title}</h2>
                  <div className="related-post-meta">
                    <Link to={`/blog/author/${node.primary_author.slug}`} className="related-post-author">{node.primary_author.name}</Link>
                    <p className="related-post-date">{node.published_at}</p>
                  </div>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <p className="text-center">No related Posts Available</p>
        )}
      </div>
    </>
  );
};

export default RelatedPosts;
